
/* Style the modal */
.ldg--mod {
  position: fixed; /* Zmiana z absolute na fixed */
  z-index: 999999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100vw; /* Zajmuje całą szerokość widoku */
  height: 100vh; /* Zajmuje całą wysokość widoku */
  background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Modal Content */
.ldg-mod-con {
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  border-radius: 5px;
  display: flex;
  align-items: center;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: #FBFBFF;
}
.ldg-mod-t {
  margin-left: 14px;
  font-size: 14px;
}
/* Create the circle loader with pure CSS */
.ldg-mod-l {
  width: 50px;
  height: 50px;
  border: 8px solid #ddd;
  border-top: 8px solid #40466a;
  border-bottom: 8px solid #40466a;
  border-radius: 50%;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  object-fit: cover;  /* Zapewnia, że obraz będzie dobrze wyglądał, nawet jeśli jego wymiary są inne niż kontenera */
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}