.cg--wrp {
    background: #F9FBFE;
    box-shadow: 0px 4px 24px rgba(12, 12, 12, 0.12);
    border-radius: 28px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    /* max-width: 1080px; */

    /* padding-right: 42px;  */

    /* display: flex; */
    justify-content: space-evenly;
    flex-direction: row;
    flex-wrap: wrap;

    /* border: 1px solid red;  */
}
.cg--wrp-without-box-shadow {
    flex: none;
    /* order: 1; */
    align-self: stretch;
    flex-grow: 0;
    /* max-width: 1080px; */

    /* padding-right: 42px;  */

    /* display: flex; */
    justify-content: space-evenly;
    flex-direction: row;
    flex-wrap: wrap;

    /* border: 1px solid red;  */
}
.cg--cat-l {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    /* flex-wrap: wrap; */
    gap: 4px;

    white-space: wrap;

    /* overflow-y: auto; */
    overflow-y: hidden;

    scrollbar-width: none;
    /* scrollbar-width: thin; */
    scrollbar-color: transparent;

    width: 317px;
}
.cg--cat-i {
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 12px;
    gap: 12px;
    
    height: 24px;

    /* Primary - Blue */
    background: #40466a;
    border: 2px solid #40466a;
    border-radius: 14px;
    border: 0.5px solid #40466a;

    /* Inside auto layout */
    flex: none;
    order: 3;
    flex-grow: 0;

    /* Desktop/sText_Bold */
    font-family: 'Asap';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height */

    /* White */
    color: #FBFBFF;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}
.cg--cat-i:hover {
    border: 0.5px solid #000732;
    background: #FBFBFF;
    color: #000732;
}
.cg--empty-l {
    height: 24px;
}
.cg--wrp-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    gap: 10px;
    margin-top: 32px;

    height: 36px;

    /* Black */
    background: #40466a;
    border-radius: 14px;

    /* Inside auto layout */
    flex: none;
    order: 2;
    align-self: center;
    flex-grow: 0;

    font-family: 'Asap';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height */

    /* White */
    color: #FBFBFF;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

    border: 2px solid #40466a;
    /* width: 99%; */
    width: 100%;

    /* Dodajemy przejście dla płynnej animacji */
    transition: box-shadow 0.3s, background-color 0.3s, color 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
}
.cg--wrp-btn::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: #FBFBFF;
    transition: transform 0.3s ease-in-out;
    z-index: -1;
}
button.cg--wrp-btn:hover::before {
    transform: translateX(100%);
}
button.cg--wrp-btn:hover {
    color: #000732; /* Czcionka zmienia kolor na ciemny */
}
.cg--wrp-pagination {
    margin-top: 32px;
}

.spn-cnt {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

/* BookContainer */
.book-container {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    align-items: center;
    width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
    /* border: 1px solid blue; */
}
.book-container-image {
    margin: 14px 24px 14px 24px;
    cursor: pointer;
}
.book-container-image-img {
    /* width: 100px; */
    /* height: 150px; */
    border-radius: 14px;
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Animacja przejścia */
    box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.3); /* Początkowy cień */
}
.book-container-image-img:hover {
    transform: scale(1.05); /* Lekkie powiększenie */
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5); /* Większy cień dla efektu uwypuklenia */
}

.book-container-info {
    margin-top: 14px;
    text-align: left;
    margin-right: 20px;
    word-wrap: break-word;
    flex: 1; 
}
.book-container-info-title:hover {
    color: #ABC4F0;
}
.book-container-info h1 {
    font-family: 'Asap';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;
    color: #000000;
}

.book-container-info-hdr {
    font-family: 'Asap';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 140%;
    color: #000000;
}
.book-container-info-hdr-fav {
    font-family: 'Asap';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 140%;
    color: #ABC4F0;
}

.book-container-info-txt {
    font-family: 'Asap';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 140%;
    color: #40466a;
}
.book-container-info-txt-fav {
    font-family: 'Asap';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 140%;
    color: #ABC4F0;
}

.book-container-info-txt-btn:hover {
    color: #ABC4F0;
}
.book-container-info-txt-btn-fav:hover {
    color: #40466a;
}

.book-container-info-txt-btn:hover .book-container-info-ico {
    fill: #ABC4F0; /* Zmiana koloru ikony */
}
.book-container-info-txt-btn-fav:hover .book-container-info-ico-fav {
    fill: #40466a; /* Zmiana koloru ikony */
}

.book-container-info-ico {
    font-size: 16px;
    margin:6px 4px 8px 0px;
    transition: color 0.3s ease; /* Dodaj przejście, jeśli chcesz płynną zmianę */
}
.book-container-info-ico-fav {
    font-size: 16px;
    margin:6px 4px 8px 0px;
    transition: color 0.3s ease; /* Dodaj przejście, jeśli chcesz płynną zmianę */
}

.book-container-e-r-sec {
    position: absolute;
    right: 10px; /* Odległość od prawej krawędzi kontenera */
    top: 50%; /* Ustawienie na środku kontenera w pionie */
    transform: translateY(-50%); /* Przesunięcie w górę o połowę swojej wysokości */
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    margin: 0px 14px 0px 0px;

    /* border: 1px solid blue; */
}
.book-container-e-r-ico {
    color:#40466a;
    fill: #40466a; /* Zmiana koloru ikony */
    cursor: pointer;
}
.book-container-e-r-ico:hover {
    color:#ABC4F0;
    fill: #ABC4F0; /* Zmiana koloru ikony */
}

/* BookAmazonAffiliate */
.book-container-amazon-affiliate {
    /* margin: 14px 24px 14px 24px; */
    cursor: pointer;
}
.book-container-amazon-affiliate-icon {
    /* width: 100px; */
    /* height: 150px; */
    border-radius: 14px;
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Animacja przejścia */
    box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.3); /* Początkowy cień */
    padding:8px;
}
.book-container-amazon-affiliate-icon:hover {
    transform: scale(1.05); /* Lekkie powiększenie */
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5); /* Większy cień dla efektu uwypuklenia */
} 

/* Pokaż szczegóły */
.cg--btn-location {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 14px;
    gap: 12px;

    /* width: 282px; */
    height: 32px;

    background: #40466a;
    border-radius: 14px;
    border: 1px solid #40466a;

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    color: #FBFBFF;

    margin-top: 8px;
    margin-bottom: 14px;

    /* Dodajemy przejście dla płynnej animacji */
    transition: box-shadow 0.3s, background-color 0.3s, color 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;

    cursor: pointer;
}
.cg--btn-location::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: #FBFBFF;
    transition: transform 0.3s ease-in-out;
    z-index: -1;
}
button.cg--btn-location:hover::before {
    transform: translateX(100%);
}
button.cg--btn-location:hover {
    color: #000732; /* Czcionka zmienia kolor na ciemny */
}
.cg--btn-location-t {
    font-family: 'Asap';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 125%;
    flex: none;
    order: 0;
    display: flex;
    place-items: center;
}
.cg--ico {
    width: 16px;
    /* height: 22px; */
    height: auto;
    margin-right: 6px;
    
    flex: none;
    order: 0;
    flex-grow: 0;
}

/* OnTablet */
@media only screen and (min-width: 768px) and (max-width: 1200px) {
    .cg--wrp,
    .cg--wrp-without-box-shadow {    
        padding-right: 0px;
        width: unset;
    }
}
/* OnMobile */
@media only screen and (min-width: 0px) and (max-width: 767px) {
    .cg--wrp,
    .cg--wrp-without-box-shadow {    
        padding-right: 0px;
        width: unset;
    }
    .book-container {
        padding-bottom: 24px;
        align-items: flex-start;
    }
    .book-container-info {
        text-align: left;
        margin-right:unset;
        margin-left:24px;
    }
    .book-container-image {
        margin: 24px 0px 0px 0px;
        margin-left:auto; 
        margin-right:auto; 
    }
    .book-container-e-r-sec {
        position: absolute;
        right: 14px; /* Odległość od prawej krawędzi kontenera */
        bottom: 24px; /* Odległość od dolnej krawędzi kontenera */
        display: flex;
        flex-direction: row; /* Elementy w wierszu */
        justify-content: center;
        align-items: center;
        transform: none; /* Usunięcie transformacji */
        top: auto; /* Usunięcie ustawienia top zdefiniowanego wcześniej */
    }
}
  
@media (min-width: 768px) {
    .book-container {
        flex-direction: row;
    }
    .book-image {
        margin-right: 20px;
        margin-bottom: 0;
    }
    .book-info {
        text-align: left;
    }
}
  