.no-results-content--wrp {
    background: #F9FBFE;
    box-shadow: 0px 4px 24px rgba(12, 12, 12, 0.12);
    border-radius: 28px;
    flex: none;
    order: 1;
    align-self: stretch;

    background: url('/assets/images/bg/selected-for-you-bg.svg');
    background-size: cover;

    /* max-width: 1080px; */
    flex-grow: 1!important;
    width: 100%;
    /* width: 540px; */
}
.no-results-content--wrp-r {
    display: flex;
    flex-direction: row;
    padding-top: 14px;
    /* position: relative; */
}
.no-results-content--wrp-r-i {
    width: 100%;
    height: auto;
    justify-content: center;
    align-items: center;
    align-self: center;
}
.no-results-content--wrp-r-i:first-child {
    text-align: center;
    min-height: 427px;
}
.no-results-content--wrp-r-i-img {
    margin: 36px 42px;
}
.no-results-content--wrp-r-i-g {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 26px;
}
.no-results-content--wrp-r-i-g-hdr {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    flex: none;
    order: 0;
    flex-grow: 0;
    font-family: 'Asap Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    /* font-size: 2.5vw; */
    line-height: 46px;
    color: #142C72;
}
.no-results-content--wrp-r-i-g-t {
    font-family: 'Asap Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    /* font-size: 1.8vw; */
    line-height: 38px;
    color: #000732;
    flex: none;
    order: 1;
    flex-grow: 0;

    margin-top: 14px;
}
.no-results-content--wrp-r-i-g-btn {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    flex: none;
    order: 1;
    flex-grow: 0;
}
.no-results-content--wrp-r-i-g-btn-d {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 34px;
    gap: 10px;
    border: 3px solid #40466a;
    background: #40466a;
    border-radius: 14px;
    flex: none;
    order: 0;
    flex-grow: 0;
    font-family: 'Asap';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #FBFBFF;
    min-width: 194.5px;
}
button.no-results-content--wrp-r-i-g-btn-d:hover {
    /* border: 3px solid #2874DA;
    background: #F9FBFE;
    color: #2874DA; */
    border: 3px solid #ABC4F0;
    background: #ABC4F0;
    color: #000732;
}

/* verticalLayout */
.no-results-content--wrp-verticalLayout {  
    background: #F9FBFE;
    box-shadow: 0px 4px 24px rgba(12, 12, 12, 0.12);
    border-radius: 28px;
    flex: none;
    order: 1;
    align-self: stretch;

    background: url('/assets/images/bg/selected-for-you-bg.svg');
    background-size: cover;

    /* max-width: 1080px; */
    flex-grow: 1!important;
    width: 100%;
    /* width: 540px; */
      
    padding-right: 0px;
    width: unset;
}
.no-results-content--wrp-r-verticalLayout {
    display: flex;
    flex-direction: row;
    padding-top: 14px;
    /* position: relative; */

    flex-direction: column;
}
.no-results-content--wrp-r-i-verticalLayout {
    width: 100%;
    height: auto;
    justify-content: center;
    align-items: center;
    align-self: center;
}
.no-results-content--wrp-r-i-verticalLayout:first-child {
    text-align: center;
    /* min-height: 427px; */
}
.no-results-content--wrp-r-i-img-verticalLayout {
    margin: 36px 42px;
    width: 40%;
    height: auto;
}
.no-results-content--wrp-r-i-g-verticalLayout {
    display: flex;
    flex-direction: column;
    padding: 0px;
    gap: 26px;
    
    justify-content: center;
    align-items: center;
    align-self: center;
    padding: 0px 4px 42px 4px;

    text-align: center;
    margin-left: auto;
    margin-right: auto;
}
.no-results-content--wrp-r-i-g-hdr-verticalLayout {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 8px;
    flex: none;
    order: 0;
    flex-grow: 0;
    font-family: 'Asap Condensed';
    font-style: normal;
    font-weight: 700;
    color: #142C72;
    
    font-size: 28px;
    line-height: 34px;
}
.no-results-content--wrp-r-i-g-t-verticalLayout {
    font-family: 'Asap Condensed';
    font-style: normal;
    font-weight: 400;
    color: #000732;
    flex: none;
    order: 1;
    flex-grow: 0;

    margin-top: 14px;

    font-size: 24px;
    line-height: 27px;
    justify-content: center;
    align-items: center;
    align-self: center;
}
.no-results-content--wrp-r-i-g-btn-verticalLayout {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    flex: none;
    order: 1;
    flex-grow: 0;

    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    gap: 8px;
}
.no-results-content--wrp-r-i-g-btn-d-verticalLayout {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 34px;
    gap: 10px;
    border: 3px solid #40466a;
    background: #40466a;
    border-radius: 14px;
    flex: none;
    order: 0;
    flex-grow: 0;
    font-family: 'Asap';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #FBFBFF;
    min-width: 194.5px;
    
    min-width: 30vw;
}

/* OnTablet */
@media only screen and (min-width: 768px) and (max-width: 1200px) {
    .no-results-content--wrp {    
        padding-right: 0px;
        width: unset;
    }
    .no-results-content--wrp-r {
        flex-direction: column;
    }
    .no-results-content--wrp-r-i-img {
        width: 40%;
        height: auto;
    }
    .no-results-content--wrp-r-i-g {
        justify-content: center;
        align-items: center;
        align-self: center;
        padding: 0px 24px 42px 24px;
    }
    .no-results-content--wrp-r-i-g-hdr {
        font-size: 26px;
        line-height: 27px;
    }
    .no-results-content--wrp-r-i-g-t {
        font-size: 20px;
        line-height: 27px;
        justify-content: center;
        align-items: center;
        align-self: center;
    }
    .no-results-content--wrp-r-i-g-btn {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-self: center;
        gap: 8px;
    }
    .no-results-content--wrp-r-i-g-btn-d {
        min-width: 50vw;
    }
}
/* OnMobile */
@media only screen and (min-width: 0px) and (max-width: 767px) {
    .no-results-content--wrp {    
        padding-bottom: 14px;
        padding-right: unset;
        width: unset;
    }
    .no-results-content--wrp-r {
        flex-direction: column;
    }
    .no-results-content--wrp-r-i:first-child {
        min-height:initial;
    }
    .no-results-content--wrp-r-i-img {
        width: 40%;
        height: auto;
    }
    .no-results-content--wrp-r-i-g {
        justify-content: center;
        align-items: center;
        align-self: center;
        padding: 0px 24px 42px 24px;
        text-align: center;
    }
    .no-results-content--wrp-r-i-g-btn {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-self: center;
        gap: 8px;
    }
    .no-results-content--wrp-r-i-g-hdr {
        font-size: 26px;
        line-height: 34px;
    }
    .no-results-content--wrp-r-i-g-t {
        font-size: 20px;
        line-height: 27px;
        justify-content: center;
        align-items: center;
        align-self: center;
    }
    .no-results-content--wrp-r-i-g-btn-d {
        min-width: 70vw;
    }
}