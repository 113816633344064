.dialogContent {
    display: flex;
    justify-content: space-between;
    flex-direction: row; /* Domyślnie wierszowo na desktopie */
}

.detailsContainer {
    flex-grow: 1;
}
.imageContainer {
    flex-basis: 100px; /* Dostosuj szerokość do rozmiaru obrazu */
    flex-shrink: 0;
    margin: 8px;

    perspective: 1000px; /* Ustawia perspektywę dla efektu 3D */
    background: #f8f8f8; /* Kolor tła książki */
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3); /* Cień książki */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px; /* Zakrzywienie rogów */
    width: 100px; /* Szerokość książki */
    height: 142px; /* Wysokość książki */

    overflow: hidden; /* Zapewnia, że cień przestrzega zaokrąglenia rogów */
    position: relative;
}
.imageContainer::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 10px; /* Zakrzywienie rogów */
    box-shadow: 5px 5px 13px rgba(0, 0, 0, 0.3);
    z-index: -1;
}
.imageBook {
    /* Style zdjęcia bez box-shadow */
    width: 100%;
    height: auto;
    transform: rotateY(5deg);
    border-radius: 10px; /* Zakrzywienie rogów */
    position: relative;
    z-index: 1; /* Upewnia się, że zdjęcie jest nad cieniem */
}

.embossedEffect {
    background: #e0e0e0; /* Podstawowy kolor tła */
    border-radius: 10px; /* Zakrzywienie rogów */
    box-shadow:
        inset 2px 2px 5px rgba(0, 0, 0, 0.6), /* Ciemny wewnętrzny cień górny-lewy */
        inset -2px -2px 5px rgba(255, 255, 255, 0.5); /* Jasny wewnętrzny cień dolny-prawy */
    padding: 3px;
    text-align: center;
}

@media only screen and (max-width: 767px) {
    .dialogContent {
        flex-direction: column; 
    }

    .detailsContainer, .imageContainer {
        width: 100%;
    }
    .imageContainer {
        margin-top: 10px;
        margin-bottom: 10px;
        width: 100px;
        height: 150px;
        margin-left: auto;
        margin-right: auto;
    }
    .imageBook {
        /* Style zdjęcia bez box-shadow */
        margin-top:14px;
        margin-bottom:14px;
        width: 100px;
        height: 150px;
        transform: rotateY(5deg);
        border-radius: 10px; /* Zakrzywienie rogów */
        position: relative;
        z-index: 1; /* Upewnia się, że zdjęcie jest nad cieniem */
    }
}
