.ftr {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 32px 180px;
    background: #000732;
    border-top: 1px solid #FBFBFF;
    position: relative; /* absolute; fixed; relative; */
    bottom: 0;
    width: 100vw;

    z-index: 1;
}
.ftr--n-l {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 24px;
    flex: none;
    flex-grow: 0;
}
.ftr--for-businesses,
.ftr--terms-and-conditions,
.ftr--privacy-policy,
.ftr--faq,
.ftr--contact,
.ftr--facebook {
    font-family: 'Asap';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    color: #FBFBFF;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}
.ftr--n-l a {
    position: relative; /* Ustawiamy link jako kontener dla pseudo-elementów */
    color: inherit; /* Nie zmieniaj koloru tekstu linku */
    text-decoration: none; /* Usuwamy domyślne podkreślenie */
}
.ftr--n-l a::after {
    content: "";
    position: absolute; /* Pozycjonujemy pseudo-element względem linku */
    left: 0; /* Początek podkreślenia od lewej krawędzi linku */
    /* bottom: 0; */ /* Ustawiamy pod linkiem */
    bottom: -5px; /* Odstęp pomiędzy tekstem a podkreśleniem */
    height: 2px;
    width: 0; /* Początkowa szerokość to 0 */
    background: #FBFBFF; /* Ustawiamy kolor podkreślenia */
    transition: width 0.3s; /* Animacja szerokości */
}   
.ftr--n-l a:hover::after {
    width: 100%; /* Szerokość podkreślenia na hover to cała szerokość linku */
}
/* Styl wyłączający podkreślenie dla linku Facebooka */
.ftr--n-l-fb::after {
    display: none; /* Wyłącza podkreślenie */
}
/* Ewentualny styl wyłączający podkreślenie dla hover linku Facebooka */
.ftr--n-l-fb:hover::after {
    display: none; /* Nadal brak podkreślenia na hover */
}
/* .ftr--n-l > div:last-child a::after {
    content: "";
    display: block;
    margin: auto;
    height: 0px;
    width: 0;
    top: 5px;
    background: transparent;
    transition: all 0.3s;
} */
/* .ftr--n-l > div:last-child a:hover::after {
    width: 100%;
    background: yellow;
} */
.ftr--n-l-fb {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 14px;
    /* gap: 10px; */
    gap: 4px;
    background: #1877F2;
    border-radius: 14px;
    flex: none;
    order: 5;
    flex-grow: 0;
    margin-bottom:2px;
}
.ftr--n-l-fb-i {
    flex: none;
    order: 0;
    flex-grow: 0;
}
.ftr--n-l-fb-i:hover{
    animation: bounce 0.5s ease;
}
@keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    25% {
      transform: translateY(-5px);
    }
    50% {
      transform: translateY(-10px);
    }
    75% {
      transform: translateY(-5px);
    }
}
.ftr--n-l-fb-t {
    font-family: 'Asap';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    color: #FBFBFF;
    flex: none;
    order: 1;
    flex-grow: 0;
}
.ftr--n-l-fb-t:hover {
    animation: breatheAnimation 2s ease-in-out infinite;
}
@keyframes breatheAnimation {
    0% { transform: translateY(0); }
    50% { transform: translateY(-5px); }
    100% { transform: translateY(0); }
}
  
.facebook-text span {
    display: inline-block;
    padding-left: 100%;
}
/* OnTablet */
@media only screen and (min-width: 768px) and (max-width: 1365px) {
    .ftr {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        /* padding: 32px 0px; */
        padding: 18px 0px 32px 0px;
    }
    .ftr--n-l {
        padding-top: 15px;
        justify-content: space-between;
        align-self: center;
        gap: 12px;
    }
}
/* OnMobile */
@media only screen and (min-width: 0px) and (max-width: 767px) {
    .ftr {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        padding: 32px 0px;
        text-align: center;
    }
    .ftr--n-l {
        flex-direction: column;
        align-items: center;
        gap: 12px;
    }
    .ftr--n-l:last-child  {
        padding-bottom: 15px;
    }
}

/* aktywacja toggle menu */
/* Standardowa szerokość ekranu, przy której zazwyczaj aktywuje się tzw. "toggle menu", to 768 pikseli. */
@media (max-width: 991px) {
    .ftr {
        display: none;
    }
}