/* *, *:before, *:after {
    box-sizing: border-box;
} */
.layout-main-content {
    flex: 1;
}
.layout-container {
    display: flex;
    flex-direction: column;
    padding: 0px;
    background: #FBFBFF;
    width: 100vw;
    min-height: 100vh;
    /* height: 100vh; */
    /* center the container to the body */
    margin: 0 auto;
}
.layout-content {
    flex: 1;
}