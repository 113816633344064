.yr {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 64px 180px 64px;
    gap: 32px;
    flex: none;
    order: 2;
    flex-grow: 0;

    /* align-self: center; */

    /* border: 8px solid red; */
}
.yr--con {
    padding-left: 0px;
    padding-right: 0px;
    
    flex-grow: 1!important;
    width: 100%;
}
.yr--con-hdr h1 {
    font-family: 'Asap';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;    
    color: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
    
    margin-bottom: 14px;
}
.yr--con-hdr-pagination {
    font-weight: 300;
    font-size: 18px;
    line-height: 32px;    
    margin-left: 8px;
    margin-bottom: 8px!important;
}
.yr--wrp {
    background: #F9FBFE;
    box-shadow: 0px 4px 24px rgba(12, 12, 12, 0.12);
    border-radius: 28px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    /* width: 1080px; */

    /* background: url('../../../assets/images/selected-for-you-bg.svg'); */
    background: url('/assets/images/bg/selected-for-you-bg.svg');
    background-size: cover;
    
    padding-right: 42px;
}
.yr--wrp-r {
    display: flex;
    flex-direction: row;
    padding: 0px;
    /* position: relative; */
}
.yr--wrp-r-i {
    width: 100%;
    height: auto;
    justify-content: center;
    align-items: center;
    align-self: center;
}
.yr--wrp-r-i:first-child {
    text-align: center;
    min-height: 427px;
}
.yr--wrp-r-i-img {
    margin: 36px 42px;
}
.yr--wrp-r-i-g {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 26px;
}
.yr--wrp-r-i-g-hdr {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    flex: none;
    order: 0;
    flex-grow: 0;
    font-family: 'Asap Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    /* font-size: 2.5vw; */
    line-height: 46px;
    color: #142C72;
    flex: none;
    order: 0;
    flex-grow: 0;
}
.yr--wrp-r-i-g-t {
    font-family: 'Asap Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    /* font-size: 1.8vw; */
    line-height: 38px;
    color: #000732;
    flex: none;
    order: 1;
    flex-grow: 0;

    margin-top: 14px;
}
.yr--wrp-r-i-g-btn {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    flex: none;
    order: 1;
    flex-grow: 0;
}
.yr--wrp-r-i-g-btn-d {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 34px;
    gap: 10px;
    border: 3px solid #40466a;
    background: #40466a;
    border-radius: 14px;
    flex: none;
    order: 0;
    flex-grow: 0;
    font-family: 'Asap';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #FBFBFF;
    min-width: 194.5px;
}
.yr--wrp-r-i-g-btn-m {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    gap: 10px;
    border: 3px solid #40466a;
    border-radius: 14px;
    flex: none;
    order: 1;
    flex-grow: 0;
    font-family: 'Asap';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #40466a;
    flex: none;
    order: 0;
    flex-grow: 0;
    width: 144.5px;
}
button.yr--wrp-r-i-g-btn-d:hover {
    /* border: 3px solid #2874DA;
    background: #F9FBFE;
    color: #2874DA; */
    border: 3px solid #ABC4F0;
    background: #ABC4F0;
    color: #000732;
}
button.yr--wrp-r-i-g-btn-m:hover {
    border: 3px solid #ABC4F0;
    background: #ABC4F0;
    color: #000732;
}
/* OnTablet */
@media only screen and (min-width: 768px) and (max-width: 1200px) {
    .yr {
        padding: 34px 0px 64px;
    }
    .yr--con {
        padding-left: 32px;
        padding-right: 32px;
    }
    .yr--wrp {    
        padding-right: 0px;
        width: unset;
    }
    .yr--wrp-r {
        flex-direction: column;
    }
    .yr--wrp-r-i-img {
        width: 80%;
        height: auto;
    }
    .yr--wrp-r-i-g {
        justify-content: center;
        align-items: center;
        align-self: center;
        padding: 0px 24px 42px 24px;
    }
    .yr--wrp-r-i-g-hdr {
        font-size: 26px;
        line-height: 27px;
    }
    .yr--wrp-r-i-g-t {
        font-size: 20px;
        line-height: 27px;
        justify-content: center;
        align-items: center;
        align-self: center;
    }
    .yr--wrp-r-i-g-btn {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-self: center;
        gap: 8px;
    }
    .yr--wrp-r-i-g-btn-d {
        min-width: 50vw;
    }
    .yr--wrp-r-i-g-btn-m {
        min-width: 50vw;
    }
}
/* OnMobile */
@media only screen and (min-width: 0px) and (max-width: 767px) {
    .yr {
        padding: 34px 0px 64px;
    }
    .yr--con {
        padding-left: 24px;
        padding-right: 24px;
    }
    .yr--con-hdr h1 {
        font-size: 24px;
        line-height: 28px;
    }
    .yr--wrp {    
        padding-right: 0px;
        width: unset;
    }
    .yr--wrp-r {
        flex-direction: column;
    }
    .yr--wrp-r-i:first-child {
        min-height:initial;
    }
    .yr--wrp-r-i-img {
        width: 80%;
        height: auto;
    }
    .yr--wrp-r-i-g {
        justify-content: center;
        align-items: center;
        align-self: center;
        padding: 0px 24px 42px 24px;
        text-align: center;
    }
    .yr--wrp-r-i-g-btn {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-self: center;
        gap: 8px;
    }
    .yr--wrp-r-i-g-hdr {
        font-size: 26px;
        line-height: 27px;
    }
    .yr--wrp-r-i-g-t {
        font-size: 20px;
        line-height: 27px;
        justify-content: center;
        align-items: center;
        align-self: center;
    }
    .yr--wrp-r-i-g-btn-d {
        min-width: 70vw;
    }
    .yr--wrp-r-i-g-btn-m {
        min-width: 70vw;
    }
}