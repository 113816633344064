.abf--sec {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 82px 180px;
    gap: 10px;
    background: #000732;
    align-self: stretch;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
    
    /* position: relative; */
    /* bottom: 0; */
    width: 100vw;

    z-index: 1;

    /* margin-top:64px; */
}
.abf--con {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 26px;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;

    /* center the container to the body */
    margin: 0 auto;
}
.abf--f-p {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}
.abf--f-p-t-p {
    font-family: 'Asap Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 46px;
    text-align: center;
    color: #FBFBFF;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}
.abf--f-p-t-a-c {
    font-family: 'Asap Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 46px;
    text-align: center;
    color: #FBFBFF;

    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;

    margin-top: 14px;
}
.abf--f-s {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}
.abf--f-s-a-c {
    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 18px;
    gap: 10px;
    background: #FBFBFF;
    border-radius: 14px;

    /* Inside auto layout */
    flex: none;
    flex-grow: 1;

    font-family: 'Asap';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000732;
    padding-left: 50px;
    padding-right: 50px;
}
.abf--f-s-r-c{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 18px;
    gap: 10px;
    border: 2px solid #FBFBFF;
    border-radius: 14px;

    /* Inside auto layout */
    flex: none;
    flex-grow: 1;

    background: #000732;

    font-family: 'Asap';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #FBFBFF;
}
button.abf--f-s-a-c:hover {
    border: 2px solid #FBFBFF;
    background: #000732;
    color: #FBFBFF;
}
button.abf--f-s-r-c:hover {
    background: #FBFBFF;
    border-radius: 14px;
    color: #000732;
}
/* OnTablet */
@media only screen and (min-width: 768px) and (max-width: 1200px) {

}
/* OnMobile */
@media only screen and (min-width: 0px) and (max-width: 767px) {
    .abf--sec {
        padding: 62px 0px;
        padding-bottom: 168px;
    }
    .abf--f-p-t-p {
        font-size: 24px;
        line-height: 28px;
    }
    .abf--f-p-t-a-c {
        font-size: 16px;
        line-height: 22px;
    }
    .abf--f-s {
        flex-direction: column;
        align-items: stretch;
        flex-grow: 0;
        padding: 0px;
        gap: 22px;
        width: 90vw;
    }
    .abf--f-s-a-c {
        padding: 4px 18px;
    }
    .abf--f-s-r-c{
        padding: 4px 18px;
    }
}