.ft--con {
    display: none;
    /* max-width: 375px; */
    width: 100%;
    padding: 4px 12px 14px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    gap: 0px;

    border-radius: 20px 20px 0px 0px;
    background: var(--White, #FBFBFF);

    box-shadow: 2px 0px 14px 0px rgba(0, 0, 0, 0.08);

    z-index: 9999;

    transition: bottom 0.3s; /* Animacja */

    position: fixed; /* Nowe: Pozycjonowanie stałe względem okna przeglądarki */
    bottom: 0;
    left: 0; /* Nowe: Wyrównanie do lewej strony */
    width: 100vw; /* Nowe: Szerokość menu */

    /* border: 4px solid red; */
}
.ft--con-menu {
    display: flex;
    flex-direction: row;
    /* justify-content: center; */ /* Elementy są wyśrodkowane wzdłuż linii. */
    /* justify-content: space-between; */ /* Elementy są równomiernie rozmieszczone; pierwszy element jest na początku linii, a ostatni na końcu. */
    /* justify-content: space-around;  */ /* Elementy są równomiernie rozmieszczone z równym odstępem wokół nich. */
    justify-content: space-evenly; /* Elementy są rozmieszczone tak, że odstęp między dwoma sąsiednimi elementami jest taki sam. */
    align-items: center;
    align-self: stretch;
}
.ft--con-menu-ico {
    /* display: flex; */
    justify-content: flex-end;
    align-self: flex-end;
    margin: 4px 6px 0px 0px; 
}
.ft--con-menu-acount-img {
    border-radius: 50%; 
    object-fit: cover;  /* Zapewnia, że obraz będzie dobrze wyglądał, nawet jeśli jego wymiary są inne niż kontenera */
}
.ft--con-menu-item {
    display: flex;
    width: 74px;
    padding: 10px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    align-self: stretch;

    cursor: pointer;

    font-family: Asap;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 14.4px */

    color: var(--Black, #000732);

    /* border: 1px solid red; */
}
/* Styl dla hover */
.ft--con-menu-item:hover, .ft--con-menu-item:focus {
    border-radius: 12px;
    background: var(--Primary---Blue, #40466a);
    color: #FBFBFF;
    fill: #FBFBFF;
}
.ft--con-menu-item:hover img:not(.no-hover-effect) {
    filter: brightness(0) saturate(100%) invert(100%); /* Efekt filtrowania */
}
.no-hover-effect {
    filter: none; /* Brak efektu filtrowania */
}
/* Styl dla active */
.ft--con-menu-item-active {
    border-radius: 12px;
    background: var(--Primary---Blue, #40466a);
    color: #FBFBFF;
    fill: #FBFBFF;
}
.ft--con-menu-item-active img:not(.no-hover-effect) {
    filter: brightness(0) saturate(100%) invert(100%); /* Efekt filtrowania */
}
.ft--con-menu-item-ftd {
    border-radius: 12px;
    background: var(--Primary---Blue, #40466a);

    color: #FBFBFF;
}
/* aktywacja toggle menu */
/* Standardowa szerokość ekranu, przy której zazwyczaj aktywuje się tzw. "toggle menu", to 768 pikseli. */
@media (max-width: 991px) {
    .ft--con {
        display: flex;
    }
}
@media (min-width: 992px) {
    .ft--con {
        display: none;
    }
}
.ft--con.close {
    display: none;
}
