.pag--sec {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    margin-left: auto;
}
.pag--pages {
    display: flex;
    height: 40px;
    justify-content: center;
    align-items: center;
}
.pag--pages-item {
    display: flex;
    padding: 8px 18px;
    margin: 0px 2px 0px 2px;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 14px;
}
.pag--pages-item.active {
    border: 2px solid var(--black, #0007);
}
.pag--pages-item-t {
    color: var(--black, #000732);
    text-align: right;
    /* Desktop/Button */
    font-size: 14px;
    font-family: 'Asap';
    font-weight: 600;
}
.pag--prev {
    display: flex;
    height: 40px;
    padding: 8px 11px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 24px;
    opacity: 0.20000000298023224;
    background: var(--black, #000732);
    margin-right: 5px;
}
.pag--nxt {
    display: flex;
    height: 40px;
    padding: 11px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 24px;
    background: var(--black, #000732);
    margin-left: 5px;
}
.pag--ico {
    width: 18px;
    height: 18px;
}
/* OnTablet */
@media only screen and (min-width: 768px) and (max-width: 1200px) {
}
/* OnMobile */
@media only screen and (min-width: 0px) and (max-width: 767px) {
    .pag--pages-item {
        display: flex;
        padding: 8px 14px;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        border-radius: 14px;
    }
}

.pag--prev:hover, .pag--nxt:hover {
    animation: pulse 1.5s infinite;
    box-shadow: inset 0 0 2px #000;  /* Cień wewnętrzny dla efektu wklęsłości */
    /* cursor: pointer; */
}
.pag--pages-item:hover {
    animation: pulse 1.5s infinite;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.6), inset 0 -3px 5px rgba(255, 255, 255, 0.5);
    /* cursor: pointer; */
}
@keyframes pulse {
    0% {
      transform: scale(1);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }
    70% {
      transform: scale(1.05);
      box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0);
    }
    100% {
      transform: scale(1);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}