.container {
    font-family: 'Asap';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    text-align: left;
    color: #222222; /* domyślny kolor */

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 10px 0px 0px;
    /* margin-top: 20px; */
}

.primary {
    color: #FBFBFF;
}
