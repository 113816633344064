.nvn--navbar-con {
    width: 100%;
    padding-left: 180px;
    padding-right: 180px;
    z-index: 100;
    box-shadow: 0px 4px 24px rgba(12, 12, 12, 0.12);
}
.bg-light {
    --bs-bg-opacity: 1;
    background-color: #FBFBFF!important;
}
.navbar-toggler {
    color: transparent;
    background-color: transparent;
    border: transparent;
    border-radius: 3.16667px;
}
.navbar-brand {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 10px;
    flex: none;
    order: 0;
    flex-grow: 0;
}
.navbar-brand:hover {
    animation: pulse-animation 2s infinite alternate;
}
@keyframes pulse-animation {
    from {
      transform: scale(1);
      opacity: 1;
    }
    to {
      transform: scale(1.05);
      opacity: 0.9;
    }
}
.nvn--navbar-brand-text {
    font-family: 'Asap';
    font-style: italic;
    font-weight: 700;
    font-size: 16px;
    line-height: 17px;
    color: #40466a;
    flex: none;
    order: 0;
    flex-grow: 0;
    /* padding-right: 14px; */
}
.nav-link {
    font-family: 'Asap';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color:#40466a;
}
.nvn--nav-l {
    font-family: 'Asap';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #000732;

    flex: none;
    order: 0;
    flex-grow: 0;

    margin-left: 5px;

    padding-right: 0px!important;
    padding-left: 0px!important;

    margin-right: var(--bs-navbar-nav-link-padding-x);
    margin-left: var(--bs-navbar-nav-link-padding-x);
}
.nvn--nav-ll {
    font-family: 'Asap';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #000732!important;

    flex: none;
    order: 0;
    flex-grow: 0;
}
.nvn--nav-l:last-child {
    margin-right: 10px;
}
.nvn--nav-l-ftd {
    align-items: center;
    padding: 8px 24px!important;
    background: #40466a;
    border-radius: 14px;
    color: #FBFBFF!important;
    flex: none;
    order: 0;
    flex-grow: 0;
    white-space: nowrap;
}
.nvn--navbar-s {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    order: 0;
    flex-grow: 1;
    padding-left: 34px;
    padding-right: 40px;
    /* width: 25vw; */
}
.nvn--navbar-s-i {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 34px;
    gap: 10px;
    background: #FBFBFF;
    border: 1px solid #000732;
    border-radius: 14px 0px 0px 14px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 1;
    color: #40466a;
}
.nvn--navbar-s-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    gap: 10px;
    background: #40466a;
    border-radius: 0px 14px 14px 0px;

    font-family: 'Asap';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #FBFBFF;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    align-self: stretch;
}
.nvn--navbar-s-btn:hover {
    background: #ABC4F0!important;;
    border-color: #ABC4F0!important;;
    border-radius: 0px 14px 14px 0px!important;
    color: #000732;
}
.nvn--navbar-s-ico {
    position: absolute;
    left: 12px;
    right: 2.86%;
    top: 26%;
    bottom: 0%;
    z-index: 9999; /*this will keep the icon appearing all time, even when on input::focus*/
}
.nvn--navbar-f-ico {
    width: 24px;
    margin-right: 8px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    margin-bottom: 2px;
}
.nvn--navbar-d-ico {
    width: 24px;
    margin-right: 8px;
    /* box-shadow: 0 3px 10px rgb(0 0 0 / 0.2); */
    margin-bottom: 2px;
}
#basic-nav-dropdown .nvn--navbar-f-ico {
    margin-right: 4px;
}
.nvn--navbar-b-n-c-p {
    font-family: 'Asap';
    font-style: italic;
    font-weight: 500;
    font-size: 10px;
    line-height: 17px;
    color: #40466a;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}
.nvn--nav-l-ftd:hover {
    background: #ABC4F0;
    color: #000732!important;
    text-decoration: none;
}
.nvn--nav-l-ftd[aria-current="page"] {
    background: #ABC4F0;
    color: #000732!important;
    text-decoration: none;
}
.nvn--nav-l-udl {
    position: relative; /* Pozycjonujemy kontener dla pseudo-elementu */
    display: inline-block; /* Zapewnia, że szerokość elementu jest jak tekst */
    white-space: nowrap; /* Zapobiega łamaniu tekstu na kilka linii */

    /* border: 1px solid red; */
}
.nvn--nav-l-udl:after {
    content: "";
    position: absolute; /* Pozycjonujemy pseudo-element */
    left: 0;
    bottom: 1px; /* Ustawiamy na dolnym brzegu tekstu */
    height: 2px;
    width: 100%; /* Początkowa szerokość to cała szerokość elementu */
    background: transparent; /* Początkowe tło transparentne */
    transform-origin: bottom right;
    transition: transform 0.25s ease-out, background-color 0.25s; /* Dodajemy animację koloru tła */
    transform: scaleX(0); /* Początkowo element jest 'zwinięty' */
}
.nvn--nav-l-udl:hover:after {
    transform: scaleX(1); /* 'Rozwijamy' podkreślenie */
    background: #000732; /* Zmieniamy kolor tła podczas hover */
}
.nvn--nav-l-udl[aria-current="page"]:after {
    content: ""; /* Powtórz tę deklarację, jeśli jest potrzebna */
    transform: scaleX(1); /* Element jest pełny na stronie bieżącej */
    background: #000732; /* Kolor tła dla podkreślenia strony bieżącej */
}
.nvn--nav-profile-img {
    border-radius: 50%;  /* Sprawi, że zdjęcie będzie okrągłe */
    /*box-shadow: inset 0 0 2px #000;*/  /* Cień wewnętrzny dla efektu wklęsłości */
    /* border: 1px solid white; */ /* Białe obramowanie wokół zdjęcia */
    /*background-color: #eee;*/  /* Szary kolor tła dla zdjęcia */
    justify-content: center;
    cursor: pointer;
    /* margin-right: -5px; */
    margin-top: 2px;
    object-fit: cover;  /* Zapewnia, że obraz będzie dobrze wyglądał, nawet jeśli jego wymiary są inne niż kontenera */
}
.nvn--nav-profile-img:hover {
    animation: pulse 1.5s infinite;
}
.nvn--nav-geo-user-img {
    border-radius: 50%;  /* Sprawi, że zdjęcie będzie okrągłe */
    /*box-shadow: inset 0 0 2px #000;*/  /* Cień wewnętrzny dla efektu wklęsłości */
    /* border: 1px solid white; */ /* Białe obramowanie wokół zdjęcia */
    /*background-color: #eee;*/  /* Szary kolor tła dla zdjęcia */
    justify-content: center;
    cursor: pointer;
    /* margin-right: -5px; */
    margin-top: 2px;
    margin-left: 3px;
    object-fit: cover;  /* Zapewnia, że obraz będzie dobrze wyglądał, nawet jeśli jego wymiary są inne niż kontenera */
}
.nvn--nav-geo-user-img:hover {
    animation: pulse 1.5s infinite;
}
@keyframes pulse {
    0% {
      transform: scale(1);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }
    70% {
      transform: scale(1.05);
      box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0);
    }
    100% {
      transform: scale(1);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}
.nvn--navbar-con-menu {
    flex-direction: row;
    justify-content: center !important;
    align-items: center;
}
.nvn--navbar-con-menu-mobile {
    display: none;
}
.nvn--navbar-con-menu-content {
    display: flex;
}
/* OnTablet */
@media only screen and (min-width: 768px) and (max-width: 1200px) {
    .nvn--navbar-con {
        padding-left: 15px;
        padding-right: 15px;
    }
}
/* OnMobile */
@media only screen and (min-width: 0px) and (max-width: 767px) {
    .nvn--navbar-con {
        padding-left: 15px;
        padding-right: 15px;
    }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .nvn--navbar-s {
        display: flex;
        flex-direction: row;
        align-items: center;
        /* padding: 0px!important; */
        /* width: 35vw; */
    }
    .nvn--navbar-content-mobile {
        display: none;
    }
}
@media only screen and (min-width: 1201px) and (max-width: 1350px) {
    .nvn--navbar-con {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
    }
}

/* aktywacja dla toggle menu - urządzenie mobilne */
/* Standardowa szerokość ekranu, przy której zazwyczaj aktywuje się tzw. "toggle menu", to 768 pikseli. */
/* @media (max-width: 767px) { */
@media (max-width: 991px) {
    /* .nvn--navbar-con {
        position: fixed;
    } */
    .nvn--navbar-con-menu {
        display: flex;
        flex-direction: column;
        justify-content: flex-start !important;
        align-items: flex-start;
    }
    /* Ukryj na urządzeniach mobilnych */
    .nvn--navbar-con-menu-desktop {
        display: none;
    }
    /* Pokaż na urządzeniach mobilnych */
    .nvn--navbar-con-menu-mobile {
        display: flex; /* Pozostawione dla układu flex */
        flex-direction: column; /* Pozostawione dla kierunku kolumnowego */
        justify-content: flex-start; /* Elementy są wyśrodkowane wzdłuż linii. */
        align-items: center; /* Elementy są wyśrodkowane wzdłuż osi poprzecznej. */
        padding: 32px 24px; /* Odstępy wewnętrzne */
        gap: 10px; /* Odstęp między elementami flex */
        flex-shrink: 0;

        width: 100%; /* Nowe: Szerokość menu */
        /* margin-top: 24px; */
        transition: transform 0.3s ease; /* Nowe: Animacja pokazywania/ukrywania */
        height: calc(100vh - 190px);
        overflow-y: auto; /* Włącza przewijanie pionowe */
        z-index: 99999999;

        /* border: 1px solid blue; */
        /* box-shadow: 0px -4px 24px rgba(12, 12, 12, 0.12); */
    }
    .nvn--navbar-con-menu-mobile-con {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 28px;
        flex: 1 0 0;

        padding: 8px 0px 0px 0px;
        width: 100%;

        /* border: 1px solid red; */
    }
    .nvn--navbar-con-menu-mobile-con-item {
        display: flex;
        padding: 14px 12px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        border-radius: 14px;
        background: var(--White, #FBFBFF);
        /* box-shadow: 0px -1px 0px 0px rgba(0, 21, 93, 0.10), -1px 0px 0px 0px rgba(0, 21, 93, 0.10), 1px 0px 0px 0px rgba(0, 21, 93, 0.10), 0px 1px 0px 0px rgba(0, 21, 93, 0.05), 0px 1px 1px 0px rgba(0, 21, 93, 0.01), 0px 2px 1px 0px rgba(0, 21, 93, 0.00); */
        box-shadow: 0px 3px 6px rgba(0, 21, 93, 0.12), 0px 1px 3px rgba(0, 21, 93, 0.08);
        color: var(--Black, #000732);
        font-family: Asap;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 24px */

        cursor: pointer;
    }
    .nvn--navbar-con-menu-mobile-con-item:hover {
        background: #40466a;
        color: #F9FBFE;

        fill: red; /* Zmienia kolor wypełnienia */
        stroke: blue; /* Zmienia kolor obrysu (jeśli istnieje) */
    }
    
    /* border: 1px solid red; */
    /* Ukryj FormControl/Search Form na urządzeniach mobilnych */
    .nvn--custom-form-control {
        display: none;
    }
    .nvn--nav-geo-user-img {
        margin-right: 14px;
    }
}

.dropdown-menu.show {
    max-width: 220px;
}
.dropdown-item.active, 
.dropdown-item:active {
    background-color: #40466a!important;
}

/* Spersonalizowane ikony hamburgera */
.navbar-toggler-custom-icon {
    fill: var(--Black, #000732);
    stroke-width: 1.4px;
    stroke: var(--Black, #000732);
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}

/* .nav-elements {
    max-width: 1040px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
} */
/* .nav-elements ul {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
} */
/* .nav-elements ul li:before {
    content: '';
} */
/* .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
} */
/* .nav-elements ul a {
    font-size: 16px;
    font-weight: 400;
    color: #2f234f;
    text-decoration: none;
}
.nav-elements ul a.active {
  color: #574c4c;
  font-weight: 500;
  position: relative;
}
.nav-elements ul a.active::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #2874DA;
}
.vector {
    position: absolute;
    left: 0%;
    right: -0.03%;
    top: 0%;
    bottom: -0.03%;

    background: #000000;
} */
