.html5qr-code-full-region {
    position: relative;
    padding: 0px;
    /* border: 1px solid silver; */
    border: 1px dashed #40466a!important;
    width: 100%!important;
    border-radius: 0px 0px 14px 14px;
}

.html5qr-code-full-region button {
    /* display: flex; */
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 18px;

    /* Primary - Blue */
    background: #40466a;
    border-radius: 14px;
    
    font-family: 'Asap';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;

    color: #FBFBFF;
    margin-top: 0px;
    margin-bottom: 8px;

    border: 1px solid #40466a;
}
.html5qr-code-full-region button:hover {
    background: #ABC4F0!important;
    border-color: #ABC4F0!important;
    border-radius: 14px 14px!important;
    color: #000732;
}
