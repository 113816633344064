.popupStyle {
    position: fixed;
    bottom: 20px;
    left: 20px; /* Zmieniono z '50%' na '20px' */
    transform: translateX(0); /* Zmieniono z 'translateX(-50%)' */
    background-color: rgba(64, 70, 106, 0.7);
    padding: 20px;
    z-index: 10000;
    border-radius: 10px;
    max-width: 300px;
    box-sizing: border-box;
    text-align: center;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.4);
}
.popupContentStyle {
    background-color: white;
    padding: 20px;
    border-radius: 14px;

    font-family: 'Asap';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000732;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.popupContentStyle h1 {
    font-family: 'Asap';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #000732;
}
.popupContentStyle a {
    text-decoration: none;
    font-weight: 600;
    color: #40466a;
}
.popupContentStyle a:hover {
    color: #ABC4F0;
}
.buttonStyle {
    padding: 10px 40px;
    border-radius: 8px;
    background-color: #40466a;
    color: #FBFBFF;
    border: none;
    cursor: pointer;
    margin-top: 10px;
}
.buttonStyle:hover {
    background-color: #000732;
    color: #FBFBFF;
}

@media (max-width: 768px) {
    .popupStyle {
        top: 50%; /* Ustawienie w pionie na środek */
        left: 50%; /* Ustawienie w poziomie na środek */
        transform: translate(-50%, -50%); /* Wyśrodkowanie */
        width: 90%; /* Szerokość na urządzeniach mobilnych */
        max-width: none; /* Usunięcie ograniczenia max-width */
        bottom: unset; /* Usunięcie wcześniejszego ustawienia bottom */
    }
}

